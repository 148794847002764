const vitransferLanguage = {
  "zh":{
    "timeList":[
      // {"id":"604800","name":"7天","desc":"上传后7天内有效"},
      {"id":"86400","name":"24小时","desc":"上传后24小时内有效"},
      {"id":"43200","name":"12小时","desc":"上传后12小时内有效"},
      {"id":"7200","name":"2小时","desc":"上传后2小时内有效"},
      {"id":"1800","name":"30分钟","desc":"上传后30分钟内有效"},
    ],
    "current_device": "当前设备",
    "choose_device": "选择设备",
    "choose_paper_import": "选择导入类型",
    "choose_language": "选择语言",
    "exit": "退出",
    "paper_name": "Paper",
    "meeting_name": "Meeting",
    "learning_name": "Learning",
    "picking_name": "Picking",
    "memo_name": "便签",
    "daily_name": "Daily",
    "other_name": "Other",
    "name": "名称",
    "type": "类型",
    "size": "大小",
    "time": "到期时间",
    "operation": "操作",
    "download": "下载",
    "delete": "删除",
    "del": "删除",
    "import": "导入",
    "return":"返回上一级",
    "search_all":"搜索全部文件",
    "selected":"已选中",
    "selected_file":"个文件/文件夹",
    "file":"文件",
    "folder":"文件夹",
    "no_data":"暂无数据",
    "confirm_delete":"确认删除",
    "confirm_delete_file":"确认删除文件吗？",
    "device_number":"序列号",
    "send_to":"导入列表",
    "download_list":"下载列表",
    "scan":"扫码或输入地址",
    "share":"分享",
    "download_code":"下载二维码",
    "copy_link":"复制链接",
    "share_tips":"注：分享的链接/二维码有效期为12小时",
    "choose_format":"选择格式",
    "please_choose":"请选择文件夹或者文件",
    "please_choose_format":"请选择格式",
    "format_not_exists":"该格式的文件不存在",
    "copy_success":"复制成功",
    "copy_fail":"复制失败",
    "close_download_tips":"有正在下载的文件，不能关闭列表。",
    "choose_send_device":"请选择同步的设备",
    "send_success":"同步成功",
    "send_fail":"同步出错",
    "cancel":"取消",
    "ok":"确认",
    "send":"同步",
    "no_device":"暂无设备，请在机器上重新登录，即可自动添加设备",
    "current_no_device":"暂无设备",
    "current_device_tips":"当前设备",
    "send_loading_text":"同步中",
    "loading_text":"加载中",
    "send_error":"同步失败",
    "try_download":"重试",
    "downloading":"下载中",
    "error":"服务异常",
    "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
    "support_meeting":"PNG",
    "support_paper":"PDF",
    "outTime":"提示：为保证用户数据的隐私安全，上传的文件%s后自动清空",
    "sync":"同步",
    "max_size":"文件的大小不能超过400M",
    "file_time":"文件有效时间",

    "language": "语言",
    "device_name": "设备名称",
    "support_png":"只支持png格式",
    "share_to":"链接复制成功，赶紧分享给朋友吧！",
    "create_code":"生成二维码",
    "so":"等文件",
    "valid":"链接/二维码有效期为 12 小时",
    "download_phone":"下载到手机",
    "yes":"知道了",
    "format_error":"不支持该格式的文件",
    "source":"文件来源",
    "apk_share":"分享",
    "export":"导出",
    "sync_desc":"同步说明",
    "sync_detail":"文件来源为“AiPaper 导出（ViTransfer > 导出）”支持同步，“AiPaper分享”的文件不支持同步。",
    "confirm_exit":"是否确认退出登录？",
    "exit_logo":"密码已更改，请重新登录",
  },
"tw":{
  "timeList":[
    {"id":"86400","name":"24小時","desc":"上傳後24小時內有效"},
    {"id":"43200","name":"12小時","desc":"上傳後12小時內有效"},
    {"id":"7200","name":"2小時","desc":"上傳後2小時內有效"},
    {"id":"1800","name":"30分鐘","desc":"上傳後30分鐘內有效"},
  ],
  "current_device": "當前設備",
  "choose_device": "選擇設備",
  "choose_paper_import": "選擇導入類型",
  "choose_language": "選擇語言",
  "exit": "退出",
  "paper_name": "Paper",
  "meeting_name": "Meeting",
  "learning_name": "Learning",
  "picking_name": "Picking",
  "memo_name": "便籤",
  "daily_name": "Daily",
  "other_name": "Other",
  "name": "名稱",
  "type": "類型",
  "size": "大小",
  "time": "到期時間",
  "operation": "操作",
  "download": "下載",
  "delete": "刪除",
  "del": "刪除",
  "import": "導入",
  "return":"返回上一级",
  "search_all":"搜索全部文件",
  "selected":"已選中",
  "selected_file":"個文件/文件夾",
  "file":"文件",
  "folder":"文件夾",
  "no_data":"暫無數據",
  "confirm_delete":"確認刪除",
  "confirm_delete_file":"確認刪除文件嗎？",
  "device_number":"序列號",
  "send_to":"導入列表",
  "download_list":"下載列表",
  "scan":"掃碼或輸入地址",
  "share":"分享",
  "download_code":"下載二維碼",
  "copy_link":"複製鏈接",
  "share_tips":"註：分享的鏈接/二維碼有效期為12小時",
  "choose_format":"選擇格式",
  "please_choose":"請選擇文件夾或者文件",
  "please_choose_format":"請選擇格式",
  "format_not_exists":"該格式的文件不存在",
  "copy_success":"複製成功",
  "copy_fail":"複製失敗",
  "close_download_tips":"有正在下載的文件，不能關閉列表。",
  "choose_send_device":"請選擇同步的設備",
  "send_success":"同步成功",
  "send_fail":"同步出錯",
  "cancel":"取消",
  "ok":"確認",
  "send":"同步",
  "no_device":"暫無設備，請在機器上重新登錄，即可自動添加設備",
  "current_no_device":"暫無設備",
  "current_device_tips":"當前設備",
  "send_loading_text":"同步中",
  "loading_text":"加載中",
  "send_error":"同步失敗",
  "try_download":"重試",
  "downloading":"下載中",
  "error":"服務異常",
  "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
  "support_meeting":"PNG",
  "support_paper":"PDF",
  "outTime":"提示：為保證用戶數據的隱私安全，上傳的文件%s後自動清空",
  "sync":"同步",
  "max_size":"文件的大小不能超過400M",
  "file_time":"文件有效時間",

  "language": "語言",
  "device_name": "設備名稱",
  "support_png":"只支持png格式",
  "share_to":"鏈接複製成功，趕快分享給朋友吧！",
  "create_code":"生成二維碼",
  "so":"等文件",
  "valid":"鏈接/二維碼有效期為 12 小時",
  "download_phone":"下載到手機",
  "yes":"知道了",
  "format_error":"不支持該格式的文件",
  "source":"文件來源",
  "apk_share":"分享",
  "export":"導出",
  "sync_desc":"同步說明",
  "sync_detail":"文件來源為“AiPaper 導出（ViTransfer > 導出）”支持同步，“AiPaper分享”的文件不支持同步。",
  "confirm_exit":"是否確認退出登錄？",
  "exit_logo":"密碼已更改，請重新登入",

},
  "uk":{
    "timeList":[
      {"id":"86400","name":"24 Hours","desc":"Expires 24 hours after upload"},
      {"id":"43200","name":"12 Hours","desc":"Expires 12 hours after upload"},
      {"id":"7200","name":"2 Hours","desc":"Expires 2 hours after upload"},
      {"id":"1800","name":"30 Minutes","desc":"Expires 30 minutes after upload"},
    ],
    "current_device": "current device name",
    "choose_device": "choose device",
    "choose_paper_import": "choose import paper type",
    "choose_language": "choose language",
    "exit": "Exit",
    "paper_name": "Paper",
    "meeting_name": "Meeting",
    "learning_name": "Learning",
    "picking_name": "Picking",
    "memo_name": "Memo",
    "daily_name": "Daily",
    "other_name": "Other",
    "name": "Name",
    "type": "Type",
    "size": "Size",
    "time": "Expiration date",
    "operation": "Operation",
    "download": "Download",
    "delete": "Delete",
    "del": "Del",
    "import": "Import",
    "return":"Return Pre",
    "search_all":"Search all file",
    "selected":"",
    "selected_file":" files/folders selected",
    "file":"file",
    "folder":"folder",
    "no_data":"no data",

    "confirm_delete":"confirm delete",
    "confirm_delete_file":"confirm delete file？",
    "send_to":"import list",
    "download_list":"download list",
    "device_number":"device number",
    "scan":"Scan the code or enter the address",
    "share":"Share",
    "download_code":"Download",
    "copy_link":"Copy Link",
    "share_tips":"Note: The shared link/QR code is valid for 12 hours",
    "choose_format":"choose format",
    "please_choose":"Please choose folder or file",
    "please_choose_format":"Please choose format",
    "format_not_exists":"The format not exists",
    "copy_success":"Copy success",
    "copy_fail":"Copy fail",
    "close_download_tips":"There are files being downloaded and the list cannot be closed.",
    "choose_send_device":"please choose sync device",
    "send_success":"Sync success",
    "send_fail":"Sync fail",
    "cancel":"Cancel",
    "ok":"Confirm",
    "send":"Sync",
    "no_device":"Please log in again on the machine to automatically add the device",
    "current_no_device":"no device",
    "current_device_tips":"current device",
    "send_loading_text":"Syncing",
    "loading_text":"Loading",
    "send_error":"sync fail",
    "try_download":"retry",
    "downloading":"downloading",
    "error":"server error",
    "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
    "support_meeting":"PNG",
    "support_paper":"PDF",
    "outTime":"Note: To ensure the privacy and security of user data, uploaded files will be automatically cleared after %s.",
    "sync":"Sync",
    "max_size":"The file size exceeds 400MB and cannot be uploaded.",
    "file_time":"File Expires in",

    "language":  "Language",
    "device_name":  "Device Name",
    "support_png": "Only supports PNG format",
    "share_to": "The link has been copied successfully, please share it with your friends quickly",
    "create_code": "Generate QR code",
    "so": "Waiting for files",
    "valid": "The link/QR code is valid for 12 hours",
    "download_phone": "Download to mobile phone",
    "yes": "Yes",
    "format_error": "Files in this format are not supported",
    "source":"File Source",
    "apk_share":"sharing",
    "export":"export",
    "sync_desc":"sync describe",
    "sync_detail":'Files originating from "AiPaper Export (ViTransfer > Export)" support synchronization, while files from "AiPaper Sharing" do not support synchronization.',
    "confirm_exit":"Are you sure you want to log out?",
    "exit_logo":"Password has been changed. Please log in again."


  },
  "us":{
    "timeList":[
      {"id":"86400","name":"24 Hours","desc":"Expires 24 hours after upload"},
      {"id":"43200","name":"12 Hours","desc":"Expires 12 hours after upload"},
      {"id":"7200","name":"2 Hours","desc":"Expires 2 hours after upload"},
      {"id":"1800","name":"30 Minutes","desc":"Expires 30 minutes after upload"},
    ],
    "current_device": "current device name",
    "choose_device": "choose device",
    "choose_paper_import": "choose import paper type",
    "choose_language": "choose language",
    "exit": "Exit",
    "paper_name": "Paper",
    "meeting_name": "Meeting",
    "learning_name": "Learning",
    "picking_name": "Picking",
    "memo_name": "Memo",
    "daily_name": "Daily",
    "other_name": "Other",
    "name": "Name",
    "type": "Type",
    "size": "Size",
    "time": "Expiration date",
    "operation": "Operation",
    "download": "Download",
    "delete": "Delete",
    "del": "Del",
    "import": "Import",
    "return":"Return Pre",
    "search_all":"Search all file",
    "selected":"",
    "selected_file":" files/folders selected",
    "file":"file",
    "folder":"folder",
    "no_data":"no data",

    "confirm_delete":"confirm delete",
    "confirm_delete_file":"confirm delete file？",
    "send_to":"import list",
    "download_list":"download list",
    "device_number":"device number",
    "scan":"Scan the code or enter the address",
    "share":"Share",
    "download_code":"Download",
    "copy_link":"Copy Link",
    "share_tips":"Note: The shared link/QR code is valid for 12 hours",
    "choose_format":"choose format",
    "please_choose":"Please choose folder or file",
    "please_choose_format":"Please choose format",
    "format_not_exists":"The format not exists",
    "copy_success":"Copy success",
    "copy_fail":"Copy fail",
    "close_download_tips":"There are files being downloaded and the list cannot be closed.",
    "choose_send_device":"please choose sync device",
    "send_success":"Sync success",
    "send_fail":"Sync fail",
    "cancel":"Cancel",
    "ok":"Confirm",
    "send":"Sync",
    "no_device":"Please log in again on the machine to automatically add the device",
    "current_no_device":"no device",
    "current_device_tips":"current device",
    "send_loading_text":"Syncing",
    "loading_text":"Loading",
    "send_error":"sync fail",
    "try_download":"retry",
    "downloading":"downloading",
    "error":"server error",
    "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
    "support_meeting":"PNG",
    "support_paper":"PDF",
    "outTime":"Note: To ensure the privacy and security of user data, uploaded files will be automatically cleared after %s.",
    "sync":"Sync",
    "max_size":"The file size exceeds 400MB and cannot be uploaded.",
    "file_time":"File Expires in",

    "language":  "Language",
    "device_name":  "Device Name",
    "support_png": "Only supports PNG format",
    "share_to": "The link has been copied successfully, please share it with your friends quickly",
    "create_code": "Generate QR code",
    "so": "Waiting for files",
    "valid": "The link/QR code is valid for 12 hours",
    "download_phone": "Download to mobile phone",
    "yes": "Yes",
    "format_error": "Files in this format are not supported",
    "source":"File Source",
    "apk_share":"sharing",
    "export":"export",
    "sync_desc":"sync describe",
    "sync_detail":'Files originating from "AiPaper Export (ViTransfer > Export)" support synchronization, while files from "AiPaper Sharing" do not support synchronization.',
    "confirm_exit":"Are you sure you want to log out?",
    "exit_logo":"Password has been changed. Please log in again."


  },
  "de":{
    "timeList":[
        {"id":"86400","name":"24 Stunden","desc":"Verfällt 24 Stunden nach Hochladen"},
        {"id":"43200","name":"12 Stunden","desc":"Verfällt 12 Stunden nach Hochladen"},
        {"id":"7200","name":"2 Stunden","desc":"Verfällt 2 Stunden nach dem Hochladen"},
        {"id":"1800","name":"30 Minuten","desc":"Läuft 30 Minuten nach dem Hochladen ab"},
      ],
      "current_device": "Aktueller Gerätename",
      "choose_device": "Gerät auswählen",
      "choose_paper_import": "Papiertyp für Import auswählen",
      "choose_language": "Sprache auswählen",
      "exit": "Beenden",
      "paper_name": "Paper",
      "meeting_name": "Meeting",
      "learning_name": "Learning",
      "picking_name": "Picking",
      "memo_name": "Memo",
      "daily_name": "Daily",
      "other_name": "Other",
      "name": "Name",
      "type": "Art",
      "size": "Größe",
      "time": "Ablaufdatum",
      "operation": "Vorgang",
      "download": "Herunterladen",
      "delete": "Löschen",
      "import": "Importieren",
      "return": "Zurück Vor",
      "search_all": "Alle Dateien suchen",
      "selected": "",
      "selected_file": "Ausgewählte Dateien/Ordner",
      "file": "Datei",
      "folder": "Ordner",
      "no_data": "keine Daten",
      "confirm_delete": "Löschen bestätigen",
      "confirm_delete_file": "Löschen der Datei bestätigen",
      "send_to": "importliste",
      "download_list": "liste herunterladen",
      "device_number": "Gerätenummer",
      "scan": "Code einscannen oder Adresse eingeben",
      "share": "Weiterleiten",
      "download_code": "Herunterladen",
      "copy_link": "Link kopieren",
      "share_tips": "Hinweis: Der freigegebene Link/QR-Code ist 24 Stunden gültig.",
      "choose_format": "Format auswählen",
      "please_choose_format": "Bitte Format wählen",
      "format_not_exists": "Format existiert nicht",
      "copy_success": "Kopieren erfolgreich",
      "copy_fail": "Kopieren fehlgeschlagen",
      "close_download_tips": "Dateien werden heruntergeladen und die Liste kann nicht geschlossen werden.",
      "choose_send_device": "Bitte wählen Sie ein Synchronisierungsgerät",
      "send_success": "Synchronisation erfolgreich",
      "send_fail": "Synchronisation fehlgeschlagen",
      "cancel": "Abbrechen",
      "ok": "Bestätigen",
      "send": "Synchronisieren",
      "no_device": "Um das Gerät automatisch hinzuzufügen, melden Sie sich bitte erneut am Computer an.",
      "current_no_device": "kein Gerät",
      "current_device_tips": "aktuelles Gerät",
      "send_loading_text": "Synchronisieren",
      "loading_text": "Laden",
      "send_error": "Synchronisation fehlgeschlagen",
      "try_download": "erneut versuchen",
      "downloading": "Herunterladen",
      "error": "Server-Fehler",
      "support_learning": "PDF、EPUB、AZW/AZW3、MOBI",
      "support_meeting": "PNG",
      "support_paper": "PDF",
      "outTime": "Hinweis: Hochgeladene Dateien werden nach %s automatisch gelöscht, um den Datenschutz und die Sicherheit der Benutzerdaten zu gewährleisten.",
      "sync": "Synchronisieren",
      "max_size": "Die Datei ist größer als 100MB und kann nicht hochgeladen werden.",
      "file_time": "Datei läuft ab in",

      "language":  "Sprache",
      "device_name":  "Gerätename",
      "support_png": "Unterstützt nur PNG-Format",
      "share_to": "Der Link wurde erfolgreich kopiert, bitte teilen Sie ihn schnell mit Ihren Freunden",
      "create_code": "QR-Code generieren",
      "so": "Warten auf Dateien",
      "valid": "Der Link/QR-Code ist für 12 Stunden gültig",
      "download_phone": "Download auf Handy",
      "yes": "Hab's",
      "format_error": "Dateien in diesem Format werden nicht unterstützt",
      "source":"Quelle",
      "apk_share":"teilen",
      "export":"export",
      "sync_desc":"synchronisierungsanweisungen",
      "sync_detail":'Dateien, die aus „AiPaper Export (ViTransfer > Export)“ stammen, unterstützen die Synchronisierung, während Dateien aus „AiPaper Teilen“ die Synchronisierung nicht unterstützen.',
      "confirm_exit":"Sind Sie sicher, dass Sie sich abmelden möchten?",
      "exit_logo":"Das Passwort wurde geändert. Bitte melden Sie sich erneut an."



    },
  "fr":{
    "timeList":[
        {"id":"86400","name":"24 heures","desc":"Expire 24 heures après le téléchargement"},
        {"id":"43200","name":"12 heures","desc":"Expire 12 heures après le téléchargement"},
        {"id":"7200","name":"2 heures","desc":"Expire 2 heures après le téléchargement"},
        {"id":"1800","name":"30 minutes","desc":"Expire 30 minutes après le téléchargement"},
      ],
      "current_device": "nom de l'appareil actuel",
      "choose_device": "choisir l'appareil",
      "choose_paper_import": "choisir le type du papier à importer",
      "choose_language": "choisir la langue",
      "exit": "Quitter",
      "paper_name": "Paper",
      "meeting_name": "Meeting",
      "learning_name": "Learning",
      "picking_name": "Picking",
      "memo_name": "Memo",
      "daily_name": "Daily",
      "other_name": "Other",
      "name": "Nom ",
      "type": "Type ",
      "size": "Taille",
      "time": "Temps d'expiration",
      "operation": "Fonctionnement",
      "download": "Télécharger",
      "delete": "Supprimer",
      "import": "Importation",
      "return": "Annuler Pre",
      "search_all": "Rechercher tous les fichiers",
      "selected": "",
      "selected_file": "fichiers/dossiers sélectionnés",
      "file": "fichier",
      "folder": "dossier",
      "no_data": "pas de données",
      "confirm_delete": "confirmer la suppression",
      "confirm_delete_file": "confirmer la suppression du fichier？",
      "send_to": "importer une liste",
      "download_list": "liste des téléchargements",
      "device_number": "numéro de l'appareil",
      "scan": "Scannez le code ou entrez l'adresse",
      "share": "Partager",
      "download_code": "Télécharger",
      "copy_link": "Copier le lien",
      "share_tips": "Remarque : le lien partagé/code QR est valable pendant 24 heures.",
      "choose_format": "choisir le format",
      "please_choose_format": "Veuillez choisir le format",
      "format_not_exists": "Le format n'existe pas",
      "copy_success": "Copie réussie",
      "copy_fail": "Échec de la copie",
      "close_download_tips": "Des fichiers sont en cours de téléchargement et la liste ne peut pas être fermée.",
      "choose_send_device": "Veuillez choisir l'appareil de synchronisation",
      "send_success": "Synchronisation réussie",
      "send_fail": "Échec de la synchronisation",
      "cancel": "Annuler",
      "ok": "Confirmer",
      "send": "Synchronisation",
      "no_device": "Veuillez vous connecter à nouveau sur la machine pour ajouter automatiquement l'appareil.",
      "current_no_device": "pas d'appareil",
      "current_device_tips": "appareil actuel",
      "send_loading_text": "Synchronisation",
      "loading_text": "Chargement",
      "send_error": "échec de la synchronisation",
      "try_download": "réessayer",
      "downloading": "téléchargement",
      "error": "erreur de serveur",
      "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
      "support_meeting":"PNG",
      "support_paper":"PDF",
      "outTime": "Remarque : pour garantir la confidentialité et la sécurité des données des utilisateurs, les fichiers téléchargés seront automatiquement effacés après %s.",
      "sync": "Synchronisation",
      "max_size": "La taille du fichier dépasse 400MB et ne peut pas être téléchargée.",
      "file_time": "Le fichier expire dans",

      "language":  "la langue",
      "device_name":  "Nom de l'appareil",
      "support_png": "Seul le format PNG est supporté",
      "share_to": "Le lien a été copié avec succès, dépêchez - vous de le partager avec vos amis!",
      "create_code": "Générer un Code QR",
      "so": "Documents égaux",
      "valid": "Le lien / Code QR est valable 12 heures",
      "download_phone": "télécharger sur mobile",
      "yes": "savoir",
      "format_error": "Les fichiers dans ce format ne sont pas pris en charge",
      "source":"Sources",
      "apk_share":"partager",
      "export":"export",
      "sync_desc":"instructions de synchronisation",
      "sync_detail":'Les fichiers provenant de « AiPaper Export (ViTransfer > Export) » prennent en charge la synchronisation, tandis que les fichiers de « Partage AiPaper » ne prennent pas en charge la synchronisation.',
      "confirm_exit":"Êtes-vous sûr de vouloir vous déconnecter?",
      "exit_logo":"Le mot de passe a été changé. Veuillez vous reconnecter."


    },
    "es":{
      "timeList":[
          {"id":"86400","name":"24 Horas","desc":"Caduca 24 horas después de la subida"},
          {"id":"43200","name":"12 Horas","desc":"Caduca 12 horas después de la subida"},
          {"id":"7200","name":"2 horas","desc":"Caduca 2 horas después de la subida"},
          {"id":"1800","name":"30 minutos","desc":"Caduca 30 minutos después de la subida"},
        ],
        "current_device": "nombre del dispositivo actual",
        "choose_device": "elegir dispositivo",
        "choose_paper_import": "elegir tipo de papel de importación",
        "choose_language": "elegir idioma",
        "exit": "Salir ",
        "paper_name": "Paper",
        "meeting_name": "Meeting",
        "learning_name": "Learning",
        "picking_name": "Picking",
        "memo_name": "Memo",
        "daily_name": "Daily",
        "other_name": "Other",
        "name": "Nombre",
        "type": "Tipo",
        "size": "Tamaño",
        "time": "Fecha de vencimiento",
        "operation": "Funcionamiento",
        "download": "Descargar",
        "delete": "Eliminar",
        "import": "Importar",
        "return": "Volver Pre",
        "search_all": "Buscar todo el archivo",
        "selected": "",
        "selected_file": "archivos/carpetas seleccionados",
        "file": "archivo",
        "folder": "carpeta",
        "no_data": "sin datos",
        "confirm_delete": "confirmar eliminar",
        "confirm_delete_file": "¿Confirmar eliminar archivo？",
        "send_to": "importar lista",
        "download_list": "descargar lista",
        "device_number": "número de dispositivo",
        "scan": "Escanea el código o introduce la dirección",
        "share": "Compartir",
        "download_code": "Descargar",
        "copy_link": "Copiar Enlace",
        "share_tips": "Nota: El enlace compartido/código QR es válido durante 24 horas",
        "choose_format": "elegir formato",
        "please_choose_format": "Por favor, elige el formato",
        "format_not_exists": "El formato no existe",
        "copy_success": "Copia exitosa",
        "copy_fail": "Fallo al copiar",
        "close_download_tips": "Se están descargando archivos y no se puede cerrar la lista.",
        "choose_send_device": "Por favor, elige el dispositivo de sincronización",
        "send_success": "Sincronización exitosa",
        "send_fail": "Fallo al sincronizar",
        "cancel": "Cancelar",
        "ok": "Confirma",
        "send": "Sincronizar",
        "no_device": "Por favor, inicia sesión de nuevo en la máquina para añadir automáticamente el dispositivo",
        "current_no_device": "ningún dispositivo",
        "current_device_tips": "dispositivo actual",
        "send_loading_text": "Sincronización",
        "loading_text": "Cargando",
        "send_error": "fallo al sincronizar",
        "try_download": "reintentar",
        "downloading": "descargando",
        "error": "error del servidor",
        "support_learning": "PDF、EPUB、AZW/AZW3、MOBI",
        "support_meeting": "PNG",
        "support_paper": "PDF",
        "outTime": "Nota: Para garantizar la privacidad y seguridad de los datos de los usuarios, los archivos cargados se borrarán automáticamente después de %s.",
        "sync": "Sincronizar",
        "max_size": "El tamaño del archivo supera los 400MB y no se puede subir.",
        "file_time": "El Archivo Caduca en",

        "language":  "Idioma",
        "device_name":  "Nombre del equipo",
        "support_png": "Solo se admite el formato png",
        "share_to": "El enlace se copió con éxito, ¡ comparte con tus amigos!",
        "create_code": "Generar Código qr",
        "so": "Esperar documentos",
        "valid": "El período de validez del enlace / código QR es de 12 horas",
        "download_phone": "Descargar al teléfono móvil",
        "yes": "Lo tengo",
        "format_error": "Archivos que no admiten este formato",
        "source":"Fuente",
        "apk_share":"compartir",
        "export":"exportar",
        "sync_desc":"instrucciones de sincronización",
        "sync_detail":'Los archivos provenientes de "AiPaper Exportar (ViTransfer > Exportar)" admiten la sincronización, mientras que los archivos de "Compartir AiPaper" no admiten la sincronización.',
        "confirm_exit":"¿Confirmas que deseas cerrar sesión?",
        "exit_logo":"Le mot de passe a été changé. Veuillez vous reconnecter."


      },
      "ko":{
        "timeList":[
            {"id":"86400","name":"24 시간","desc":"업로드 후 24시간 만료"},
            {"id":"43200","name":"12시간","desc":"업로드 후 12시간 후 만료"},
            {"id":"7200","name":"2 시간","desc":"업로드 후 2시간 만료"},
            {"id":"1800","name":"30 분","desc":"업로드 후 30분 만료"},
          ],
          "current_device": "현재 장치 이름",
          "choose_device": "장치 선택",
          "choose_paper_import": "paper 유형 선택",
          "choose_language": "언어 선택",
          "exit": "종료",
          "paper_name": "Paper",
          "meeting_name": "Meeting",
          "learning_name": "Learning",
          "picking_name": "Picking",
          "memo_name": "Memo",
          "daily_name": "Daily",
          "other_name": "Other",
          "name": "이름",
          "type": "유형",
          "size": "크기",
          "time": "만료 시간",
          "operation": "작업",
          "download": "다운로드",
          "delete": "삭제",
          "import": "가져오기",
          "return": "이전으로 돌아가기",
          "search_all": "모든 파일 검색",
          "selected": "",
          "selected_file": "선택한 파일/폴더",
          "file": "파일",
          "folder": "폴더",
          "no_data": "데이터 없음",
          "confirm_delete": "삭제 확인",
          "confirm_delete_file": "파일을 삭제하시겠습니까? 확인하시겠습니까?",
          "send_to": "목록 가져오기",
          "download_list": "다운로드 목록",
          "device_number": "장치 번호",
          "scan": "QR 코드를 스캔하거나 주소를 입력하세요",
          "share": "공유",
          "download_code": "다운로드",
          "copy_link": "링크 복사",
          "share_tips": "참고: 공유 링크/QR 코드는 24시간 동안 유효합니다",
          "choose_format": "형식 선택",
          "please_choose_format": "형식을 선택해주세요",
          "format_not_exists": "해당 형식이 존재하지 않습니다",
          "copy_success": "복사 성공",
          "copy_fail": "복사 실패",
          "close_download_tips": "다운로드 중인 파일이 있어 목록을 닫을 수 없습니다.",
          "choose_send_device": "장치를 선택하세요",
          "send_success": "동기화 성공",
          "send_fail": "동기화 실패",
          "cancel": "취소",
          "ok": "확인",
          "send": "동기화",
          "no_device": "기계에 다시 로그인하여 장치를 자동으로 추가하세요",
          "current_no_device": "장치 없음",
          "current_device_tips": "현재 장치",
          "send_loading_text": "동기화 중",
          "loading_text": "로드 중",
          "send_error": "동기화 실패",
          "try_download": "다시 시도",
          "downloading": "다운로드 중",
          "error": "서버 오류",
          "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
          "support_meeting":"PNG",
          "support_paper":"PDF",
          "outTime": "참고: 사용자 데이터의 개인 정보 및 보안을 위해 업로드된 파일은 %s 후에 자동으로 삭제됩니다.",
          "sync": "동기화",
          "max_size": "파일 크기가 100MB를 초과하여 업로드할 수 없습니다.",
          "file_time": "파일이 만료됩니다",

          "language": "언어",
          "device_name": "장치 이름",
          "support_png":"png 형식만 지원",
          "share_to":"링크 복사 성공, 빨리 친구에게 공유!",
          "create_code":"QR코드 생성",
          "so":"파일 기다리기",
          "valid":"링크 / QR코드 유효기간은 12시간",
          "download_phone":"휴대폰으로 다운로드",
          "yes":"알았어",
          "format_error":"이 형식의 파일은 지원되지 않습니다",
          "source":"출처",
          "apk_share":"공유",
          "export":"내보내기",
          "sync_desc":"동기화 설명",
          "sync_detail":'"아이페이퍼 내보내기(ViTransfer > 내보내기)"에서 나온 파일은 동기화를 지원하지만, "아이페이퍼 공유"의 파일은 동기화를 지원하지 않습니다.',
          "confirm_exit":"로그아웃하시겠습니까?",
          "exit_logo":"비밀번호가 변경되었습니다. 다시 로그인해 주세요."


        },
    "nl":{
      "timeList":[
          {"id":"86400","name":"24 uur","desc":"Vervalt 24 uur na uploaden"},
          {"id":"43200","name":"12 uur","desc":"Verloopt 12 uur na upload"},
          {"id":"7200","name":"2 uur","desc":"Vervalt 2 uur na uploaden"},
          {"id":"1800","name":"30 minuten","desc":"Vervalt 30 minuten na uploaden"},
        ],
        "current_device": "huidige apparaatnaam",
        "choose_device": "kies apparaat",
        "choose_paper_import": "kies type importpapier",
        "choose_language": "kies taal",
        "exit": "Verlaten",
        "paper_name": "Paper",
        "meeting_name": "Meeting",
        "learning_name": "Learning",
        "picking_name": "Picking",
        "memo_name": "Memo",
        "daily_name": "Daily",
        "other_name": "Other",
        "name": "Naam",
        "type": "Type",
        "size": "Grootte",
        "time": "Vervaldatum",
        "operation": "Werking",
        "download": "Downloaden",
        "delete": "Verwijderen",
        "import": "Importeren",
        "return": "Terug Pre",
        "search_all": "Alle bestanden zoeken",
        "selected": "",
        "selected_file": "geselecteerde bestanden/mappen",
        "file": "bestand",
        "folder": "map",
        "no_data": "geen data",
        "confirm_delete": "Verwijderen bevestigen",
        "confirm_delete_file": "bestand verwijderen bevestigen?",
        "send_to": "importlijst",
        "download_list": "downloadlijst",
        "device_number": "apparaatnummer",
        "scan": "Scan de code of voer het adres in",
        "share": "Delen",
        "download_code": "Downloaden",
        "copy_link": "Link kopiëren",
        "share_tips": "Opmerking: de gedeelde link/QR-code is 24 uur geldig.",
        "choose_format": "formaat kiezen",
        "please_choose_format": "Kies formaat",
        "format_not_exists": "Het formaat bestaat niet",
        "copy_success": "Succes bij kopiëren",
        "copy_fail": "Kopiëren mislukt",
        "close_download_tips": "Er worden bestanden gedownload en de lijst kan niet worden gesloten.",
        "choose_send_device": "Kies een synchronisatieapparaat",
        "send_success": "Synchronisatie geslaagd",
        "send_fail": "Synchronisatie mislukt",
        "cancel": "Annuleren",
        "ok": "Bevestig",
        "send": "Synchroniseren",
        "no_device": "Meld je opnieuw aan op de machine om het apparaat automatisch toe te voegen",
        "current_no_device": "geen apparaat",
        "current_device_tips": "huidig apparaat",
        "send_loading_text": "Synchroniseren",
        "loading_text": "Laden",
        "send_error": "synchronisatie mislukt",
        "try_download": "opnieuw proberen",
        "downloading": "downloaden",
        "error": "serverfout",
        "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
        "support_meeting":"PNG",
        "support_paper":"PDF",
        "outTime": "Opmerking: om de privacy en veiligheid van gebruikersgegevens te waarborgen, worden geüploade bestanden automatisch gewist na %s.",
        "sync": "Synchroniseren",
        "max_size": "Het bestand is groter dan 100MB en kan niet worden geüpload.",
        "file_time": "Bestand vervalt in",

        "language":  "Taal",
        "device_name":  "Apparaatnaam",
        "support_png": "Ondersteunt alleen PNG-formaat",
        "share_to": "De link is succesvol gekopieerd, deel deze snel met je vrienden",
        "create_code": "QR-code genereren",
        "so": "Wachten op bestanden",
        "valid": "De link/QR code is geldig voor 12 uur",
        "download_phone": "Download naar mobiele telefoon",
        "yes": "Begrepen",
        "format_error": "Bestanden in dit formaat worden niet ondersteund",
        "source": "Bron",
        "apk_share": "Delen",
        "export": "Exporteren",
        "sync_desc":"synchronisatie-instructies",
        "sync_detail":'Bestanden afkomstig van "AiPaper Exporteren (ViTransfer > Exporteren)" ondersteunen synchronisatie, terwijl bestanden van "AiPaper Delen" geen synchronisatie ondersteunen.',
        "confirm_exit":"Weet u zeker dat u wilt uitloggen?",
        "exit_logo":"Het wachtwoord is gewijzigd. Log alstublieft opnieuw in."


      },
    "pt":{
      "timeList":[
          {"id":"86400","name":"24 Horas","desc":"Expira 24 horas após o upload"},
          {"id":"43200","name":"12 horas","desc":"Expira 12 horas após o upload"},
          {"id":"7200","name":"2 Horas","desc":"Expira 2 horas após o upload"},
          {"id":"1800","name":"30 Minutos","desc":"Expira 30 minutos após o upload"},
        ],
        "current_device": "nome do dispositivo atual",
        "choose_device": "escolher dispositivo",
        "choose_paper_import": "escolha o tipo de Paper para importação",
        "choose_language": "escolher idioma",
        "exit": "Sair",
        "paper_name": "Paper",
        "meeting_name": "Meeting",
        "learning_name": "Learning",
        "picking_name": "Picking",
        "memo_name": "Memo",
        "daily_name": "Daily",
        "other_name": "Other",
        "name": "Nome",
        "type": "Tipo",
        "size": "Tamanho",
        "time": "Data de expiração",
        "operation": "Operação",
        "download": "Download",
        "delete": "Excluir",
        "import": "Importar",
        "return": "Retorno Pré",
        "search_all": "Pesquisar todos os arquivos",
        "selected": "",
        "selected_file": "arquivos/pastas selecionados",
        "file": "arquivo",
        "folder": "pasta",
        "no_data": "sem dados",
        "confirm_delete": "confirmar exclusão",
        "confirm_delete_file": "confirmar exclusão do arquivo?",
        "send_to": "lista de importação",
        "download_list": "lista de download",
        "device_number": "número do dispositivo",
        "scan": "Escanear o código ou inserir o endereço",
        "share": "Compartilhar",
        "download_code": "Download",
        "copy_link": "Copiar Link",
        "share_tips": "Observação: o link/QR Code compartilhado é válido por 24 horas",
        "choose_format": "escolher formato",
        "please_choose_format": "Por favor, escolha o formato",
        "format_not_exists": "O formato não existe",
        "copy_success": "Copia bem-sucedida",
        "copy_fail": "Copia falhou",
        "close_download_tips": "Há arquivos sendo baixados e a lista não pode ser fechada.",
        "choose_send_device": "por favor, escolha o dispositivo de sincronização",
        "send_success": "Sincronização bem-sucedida",
        "send_fail": "Falha na sincronização",
        "cancel": "Cancelar",
        "ok": "Confirmar",
        "send": "Sincronizar",
        "no_device": "Por favor, faça login novamente na máquina para adicionar automaticamente o dispositivo",
        "current_no_device": "sem dispositivo",
        "current_device_tips": "dispositivo atual",
        "send_loading_text": "Sincronizando",
        "loading_text": "Carregando",
        "send_error": "falha na sincronização",
        "try_download": "tentar novamente",
        "downloading": "baixando",
        "error": "erro de servidor",
        "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
        "support_meeting":"PNG",
        "support_paper":"PDF",
        "outTime": "Observação: para garantir a privacidade e a segurança dos dados do usuário, os arquivos enviados serão automaticamente apagados após %s.",
        "sync": "Sincronizar",
        "max_size": "O tamanho do arquivo excede 100 MB e não pode ser enviado.",
        "file_time": "Arquivo Expira em",

        "language":   "Língua",
        "device_name":   "Nome do dispositivo",
        "support_png": "Suporta apenas o formato PNG.",
        "share_to":  "O link foi copiado com sucesso, compartilhe com seus amigos!",
        "create_code":  "Gerar código 2D",
        "so":  "Arquivos à espera",
        "valid":  "Link/código 2D válido por 12 horas",
        "download_phone":  "Baixar para o celular",
        "yes":  "Eu percebi.",
        "format_error":  "Arquivos com este formato não suportados",
        "source": "Fonte",
        "apk_share": "Compartilhar",
        "export": "Exportar",
        "sync_desc":"instruções de sincronização",
        "sync_detail":'Arquivos originados de "AiPaper Exportar (ViTransfer > Exportar)" suportam sincronização, enquanto arquivos de "Compartilhar AiPaper" não suportam sincronização.',
        "confirm_exit":"Tem certeza de que deseja sair?",
        "exit_logo":"A senha foi alterada. Por favor, faça login novamente."



      },
    "ja":{
      "timeList":[
          {"id":"86400","name":"24時間","desc":"アップロード後24時間で有効期限切れ"},
          {"id":"43200","name":"12時間","desc":"アップロード後12時間で有効期限が切れます"},
          {"id":"7200","name":"2時間","desc":"アップロード後2時間で有効期限切れ"},
          {"id":"1800","name":"30分","desc":"アップロード後30分で有効期限切れ"},
        ],
        "current_device": "現在のデバイス名",
        "choose_device": "デバイスを選択",
        "choose_paper_import": "インポートするペーパーの種類を選択",
        "choose_language": "言語を選択",
        "exit": "終了",
        "paper_name": "Paper",
        "meeting_name": "Meeting",
        "learning_name": "Learning",
        "picking_name": "Picking",
        "memo_name": "Memo",
        "daily_name": "Daily",
        "other_name": "Other",
        "name": "名前",
        "type": "種類",
        "size": "サイズ",
        "time": "有効期限",
        "operation": "操作",
        "download": "ダウンロード",
        "delete": "削除",
        "import": "インポート",
        "return": "前に戻る",
        "search_all": "すべてのファイルを検索",
        "selected": "",
        "selected_file": " ファイル/フォルダーが選択されました",
        "file": "ファイル",
        "folder": "フォルダー",
        "no_data": "データがありません",
        "confirm_delete": "削除の確認",
        "confirm_delete_file": "ファイルを削除しますか？",
        "send_to": "インポートリスト",
        "download_list": "ダウンロードリスト",
        "device_number": "デバイス番号",
        "scan": "コードをスキャンするかアドレスを入力してください",
        "share": "共有",
        "download_code": "ダウンロード",
        "copy_link": "リンクをコピー",
        "share_tips": "注意：共有リンク/QRコードの有効期限は24時間です",
        "choose_format": "形式を選択",
        "please_choose_format": "形式を選択してください",
        "format_not_exists": "この形式は存在しません",
        "copy_success": "コピーしました",
        "copy_fail": "コピーに失敗しました",
        "close_download_tips": "ダウンロード中のファイルがあるため、リストを閉じることができません",
        "choose_send_device": "同期するデバイスを選択してください",
        "send_success": "同期が完了しました",
        "send_fail": "同期に失敗しました",
        "cancel": "キャンセル",
        "ok": "確認",
        "send": "同期",
        "no_device": "機器で再度ログインするとデバイスが自動的に追加されます",
        "current_no_device": "デバイスがありません",
        "current_device_tips": "現在のデバイス",
        "send_loading_text": "同期中",
        "loading_text": "読み込み中",
        "send_error": "同期に失敗しました",
        "try_download": "再試行",
        "downloading": "ダウンロード中",
        "error": "サーバーエラー",
        "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
        "support_meeting":"PNG",
        "support_paper":"PDF",
        "outTime": "注意：ユーザーデータのプライバシーとセキュリティを確保するため、アップロードされたファイルは%s後に自動的に消去されます",
        "sync": "同期",
        "max_size": "ファイルサイズが100MBを超えているため、アップロードできません",
        "file_time": "ファイルの有効期限まで",

        "language": "言語",
        "device_name": "デバイス名",
        "support_png":"pngフォーマットのみサポート",
        "share_to":"リンクコピー成功、早速友達にシェアしよう！",
        "create_code":"2次元コードを生成する",
        "so":"等ファイル",
        "valid":"リンク/QRコードの有効期間は12時間",
        "download_phone":"携帯電話にダウンロード",
        "yes":"わかりました",
        "format_error":"この形式のファイルはサポートされていません",
        "source": "ソース",
        "apk_share": "共有",
        "export": "エクスポート",
        "sync_desc":"同期の説明",
        "sync_detail":'「AiPaperエクスポート（ViTransfer > エクスポート）」からのファイルは同期をサポートしますが、「AiPaperシェア」のファイルは同期をサポートしません。',
        "confirm_exit":"ログアウトしてもよろしいですか？",
        "exit_logo":"パスワードが変更されました。もう一度ログインしてください。"


      },
    "it":{
      "timeList":[
          {"id":"86400","name":"24 ore","desc":"Scade 24 ore dopo il caricamento"},
          {"id":"43200","name":"12 ore","desc":"Scade 12 ore dopo il caricamento"},
          {"id":"7200","name":"2 ore","desc":"Scade 2 ore dopo il caricamento"},
          {"id":"1800","name":"30 minuti","desc":"Scade 30 minuti dopo il caricamento"},
        ],
        "current_device": "nome dispositivo corrente",
        "choose_device": "scegli dispositivo",
        "choose_paper_import": "scegli tipo di carta da importare",
        "choose_language": "scegli lingua",
        "exit": "Esci",
        "paper_name": "Paper",
        "meeting_name": "Meeting",
        "learning_name": "Learning",
        "picking_name": "Picking",
        "memo_name": "Memo",
        "daily_name": "Daily",
        "other_name": "Other",
        "name": "Nome",
        "type": "Tipo",
        "size": "Dimensione",
        "time": "Data di scadenza",
        "operation": "Operazione",
        "download": "Download",
        "delete": "Elimina",
        "import": "Importa",
        "return": "Ritorna Pre",
        "search_all": "Cerca tutti i file",
        "selected": "",
        "selected_file": "file/cartelle selezionati",
        "file": "file",
        "folder": "cartella",
        "no_data": "nessun dato",
        "confirm_delete": "conferma eliminazione",
        "confirm_delete_file": "conferma eliminazione file？",
        "send_to": "elenco di importazione",
        "download_list": "scaricare elenco",
        "device_number": "numero dispositivo",
        "scan": "Scansiona il codice o inserisci l'indirizzo",
        "share": "Condividi",
        "download_code": "Scarica",
        "copy_link": "Copia collegamento",
        "share_tips": "Nota: il collegamento condiviso/codice QR è valido per 24 ore",
        "choose_format": "scegli formato",
        "please_choose_format": "Scegli formato",
        "format_not_exists": "Il formato non esiste",
        "copy_success": "Copia riuscita",
        "copy_fail": "Copia fallita",
        "close_download_tips": "Ci sono file in fase di download e l'elenco non può essere chiuso.",
        "choose_send_device": "scegli dispositivo di sincronizzazione",
        "send_success": "Sincronizzazione riuscita",
        "send_fail": "Sincronizzazione fallita",
        "cancel": "Annulla",
        "ok": "Conferma",
        "send": "Sincronizzazione",
        "no_device": "Accedi di nuovo sul computer per aggiungere automaticamente il dispositivo",
        "current_no_device": "Nessun dispositivo",
        "current_device_tips": "Dispositivo corrente",
        "send_loading_text": "Sincronizzazione",
        "loading_text": "Caricamento",
        "send_error": "Sincronizzazione fallita",
        "try_download": "Riprova",
        "downloading": "Download",
        "error": "Errore del server",
        "support_learning":"PDF、EPUB、AZW/AZW3、MOBI",
        "support_meeting":"PNG",
        "support_paper":"PDF",
        "outTime": "Nota: Per garantire la privacy e la sicurezza dei dati utente, i file caricati verranno automaticamente cancellati dopo %s.",
        "sync": "Sincronizzazione",
        "max_size": "La dimensione del file supera i 100 MB e non può essere caricata.",
        "file_time": "Il file scade tra",

        "language":   "Lingua",
        "device_name":   "Nome del dispositivo",
        "support_png": "Supporta solo il formato PNG.",
        "share_to":  "Il link è stato copiato con successo, condividilo subito con gli amici!",
        "create_code":  "Generare codice 2D",
        "so":  "Aspetta i documenti",
        "valid":  "Link/codice 2D valido per 12 ore",
        "download_phone":  "Scarica sul cellulare",
        "yes":  "Lo so",
        "format_error":  "File non supportati in questo formato",
        "source": "Fonte",
        "apk_share": "Condividi",
        "export": "Esporta",
        "sync_desc":"istruzioni di sincronizzazione",
        "sync_detail":'I file provenienti da "Esportazione AiPaper (ViTransfer > Esporta)" supportano la sincronizzazione, mentre i file da "Condivisione AiPaper" non supportano la sincronizzazione.',
        "confirm_exit":"Sei sicuro di voler effettuare il logout?",
        "exit_logo":"La password è stata modificata. Si prega di effettuare nuovamente l'accesso."


      },

      "ru": {
    "timeList": [
      {"id": "86400", "name": "24 часа", "desc": "Действительно в течение 24 часов после загрузки"},
      {"id": "43200", "name": "12 часов", "desc": "Действительно в течение 12 часов после загрузки"},
      {"id": "7200", "name": "2 часа", "desc": "Действительно в течение 2 часов после загрузки"},
      {"id": "1800", "name": "30 минут", "desc": "Действительно в течение 30 минут после загрузки"}
    ],
    "current_device": "Текущее устройство",
    "choose_device": "Выбрать устройство",
    "choose_paper_import": "Выбрать тип импорта",
    "choose_language": "Выбрать язык",
    "exit": "Выйти",
    "paper_name": "Paper",
    "meeting_name": "Meeting",
    "learning_name": "Learning",
    "picking_name": "Picking",
    "memo_name": "Memo",
    "daily_name": "Daily",
    "other_name": "Other",
    "name": "Название",
    "type": "Тип",
    "size": "Размер",
    "time": "Срок истечения",
    "operation": "Действие",
    "download": "Скачать",
    "delete": "Удалить",
    "del": "Удалить",
    "import": "Импорт",
    "return": "Вернуться на предыдущий уровень",
    "search_all": "Поиск всех файлов",
    "selected": "Выбрано",
    "selected_file": "файлов/папок",
    "file": "Файл",
    "folder": "Папка",
    "no_data": "Нет данных",
    "confirm_delete": "Подтвердите удаление",
    "confirm_delete_file": "Вы уверены, что хотите удалить файл?",
    "device_number": "Серийный номер",
    "send_to": "Список импорта",
    "download_list": "Список загрузок",
    "scan": "Сканируйте или введите адрес",
    "share": "Поделиться",
    "download_code": "QR-код для загрузки",
    "copy_link": "Копировать ссылку",
    "share_tips": "Примечание: Срок действия ссылки/QR-кода составляет 12 часов",
    "choose_format": "Выбрать формат",
    "please_choose": "Пожалуйста, выберите папку или файл",
    "please_choose_format": "Пожалуйста, выберите формат",
    "format_not_exists": "Файл в данном формате не существует",
    "copy_success": "Копирование успешно",
    "copy_fail": "Ошибка копирования",
    "close_download_tips": "Нельзя закрыть список, пока идет загрузка файлов.",
    "choose_send_device": "Пожалуйста, выберите устройство для синхронизации",
    "send_success": "Синхронизация успешна",
    "send_fail": "Ошибка синхронизации",
    "cancel": "Отмена",
    "ok": "Подтвердить",
    "send": "Синхронизировать",
    "no_device": "Нет устройств. Пожалуйста, войдите на устройстве, чтобы оно автоматически добавилось.",
    "current_no_device": "Нет доступных устройств",
    "current_device_tips": "Текущее устройство",
    "send_loading_text": "Синхронизация",
    "loading_text": "Загрузка",
    "send_error": "Сбой синхронизации",
    "try_download": "Повторить",
    "downloading": "Загрузка",
    "error": "Ошибка сервиса",
    "support_learning": "PDF、EPUB、AZW/AZW3、MOBI",
    "support_meeting": "PNG",
    "support_paper": "PDF",
    "outTime": "Подсказка: Для обеспечения конфиденциальности данные будут автоматически удалены через %s.",
    "sync": "Синхронизация",
    "max_size": "Размер файла не должен превышать 400 МБ",
    "file_time": "Время действия файла",

    "language": "Язык",
    "device_name": "Имя устройства",
    "support_png": "Поддерживается только формат PNG",
    "share_to": "Ссылка успешно скопирована! Поделитесь с друзьями!",
    "create_code": "Создать QR-код",
    "so": "и другие файлы",
    "valid": "Срок действия ссылки/QR-кода — 12 часов",
    "download_phone": "Скачать на телефон",
    "yes": "Понятно",
    "format_error": "Этот формат файла не поддерживается",
    "source": "Источник файла",
    "apk_share": "Поделиться",
    "export": "Экспорт",
    "sync_desc": "Описание синхронизации",
    "sync_detail": "Файлы, экспортированные из 'AiPaper (ViTransfer > Экспорт)', поддерживают синхронизацию. Файлы, созданные через 'AiPaper Поделиться', не поддерживают синхронизацию.",
    "confirm_exit": "Вы уверены, что хотите выйти из аккаунта?",
    "exit_logo":"La password è stata modificata. Si prega di effettuare nuovamente l'accesso."


  }
}

export {
  vitransferLanguage
}